import { Injectable } from '@angular/core';
import { ApiClientService } from '../api.service';

import { Store } from '@ngxs/store';
import { LessonsService } from './lessons.service';
import { ILesson, ILessonDTO, ILessonScheduleAddDTO, IScheduleFromAddDTO } from '../../models/lesson.interface';
import { Observable } from 'rxjs';
import { IResults } from '../../models/results.interface';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LessonsScheduleService extends LessonsService {
    constructor(
        public api: ApiClientService,
        store: Store
    ) {
        super(api, store);
    }

    public add(groupId: number, dto: ILessonScheduleAddDTO): Observable<void> {
        return this.api.post<IResults<ILessonDTO>>(`groups/${groupId}/lessons/schedule`, dto).pipe(map(response => null));
    }

    public edit(groupId: number, dto: ILessonScheduleAddDTO): Observable<ILesson> {
        return this.api
            .patch<IResults<ILessonDTO>>(`groups/${groupId}/lessons/schedule/${dto.id}`, dto)
            .pipe(map(response => this.parseLesson(response.results)));
    }

    public delete(groupId: number, lessonId: number): Observable<void> {
        return this.api.delete<IResults<void>>(`groups/${groupId}/lessons/${lessonId}`).pipe(map(response => null));
    }

    public changeFrom(groupId: number, dto: IScheduleFromAddDTO): Observable<ILesson> {
        return this.api.patch<IResults<ILessonDTO>>(`groups/${groupId}/schedule`, dto).pipe(map(response => this.parseLesson(response.results)));
    }

    public validateSchedule(groupId: number, dto: IScheduleFromAddDTO): Observable<{ validLastLessonDate: boolean }> {
        return this.api.post<{ validLastLessonDate: boolean }>(`groups/${groupId}/schedule/validate`, dto).pipe(map(response => response));
    }
}
