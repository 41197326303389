import { ISemesterMonth } from '../../models/lesson.interface';
import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { LessonsScheduleService } from '../../services/lessons/lessons-schedule.service';
import {
    AddLessonSchedule,
    ChangeScheduleFrom,
    DeleteLessonSchedule,
    EditLessonSchedule,
    LoadLessonsSchedule,
    ResetLessonsSchedule,
    SetLessonsScheduleGroup,
} from './lessons-schedule.actions';
import { catchError, tap } from 'rxjs/operators';
import { StateErrorHandlerService } from '../../services/paginable-state-error-handler.service';
import { Observable, throwError } from 'rxjs';
import { IGroup } from '../../models/group.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { format, isSameMonth } from 'date-fns';

export interface ILessonsScheduleStateModel {
    group: IGroup;
    months: ISemesterMonth[];
    currentMonth: ISemesterMonth;
}

@State<ILessonsScheduleStateModel>({
    name: 'lessonsSchedule',
    defaults: {
        group: null,
        months: null,
        currentMonth: null,
    },
})
@Injectable()
export class LessonsScheduleState {
    constructor(
        private schedule: LessonsScheduleService,
        private errorHandler: StateErrorHandlerService
    ) {}

    @Selector()
    public static getState(state: ILessonsScheduleStateModel) {
        return state;
    }

    @Selector()
    public static months(state: ILessonsScheduleStateModel) {
        return state.months;
    }

    @Selector()
    public static allLessons(state: ILessonsScheduleStateModel) {
        return state.months.reduce((a, b) => {
            a.lessons.push(...b.lessons);
            return a;
        }).lessons;
    }

    public static lessons(month: string) {
        return createSelector([this], (state: ILessonsScheduleStateModel) => {
            if (!state.months) {
                return null;
            }
            return state.months.find(m => format(m.month, 'M') === month.toString()).lessons;
        });
    }

    @Selector()
    public static group(state: ILessonsScheduleStateModel) {
        return state.group;
    }

    @Action(SetLessonsScheduleGroup)
    public setGroupId(ctx: StateContext<ILessonsScheduleStateModel>, { payload }: SetLessonsScheduleGroup) {
        return ctx.patchState({
            group: payload.group,
        });
    }

    @Action(ResetLessonsSchedule)
    public resetLessonsSchedule(ctx: StateContext<ILessonsScheduleStateModel>) {
        return ctx.patchState({
            months: null,
            group: null,
        });
    }

    @Action(LoadLessonsSchedule)
    public loadLessonsSchedule(ctx: StateContext<ILessonsScheduleStateModel>) {
        const group = ctx.getState()?.group;
        if (!group) {
            return;
        }

        return this.schedule.getByMonths(group.id).pipe(
            tap(newMonths => {
                let months = ctx.getState().months;

                // prevent unmutate
                if (!months) {
                    months = newMonths;
                } else {
                    for (const [i, newMonth] of newMonths.entries()) {
                        const month = months.find(l => isSameMonth(l.month, newMonth.month));

                        if (!month) {
                            months.splice(i, 0, newMonth);
                        } else {
                            month.lessons = newMonth.lessons;
                        }
                    }

                    for (const month of months) {
                        const newMonth = newMonths.find(l => isSameMonth(l.month, month.month));

                        if (!newMonth) {
                            month.lessons = [];
                        }
                    }
                }

                months = months.filter(m => m.lessons.length > 0);

                ctx.patchState({
                    months,
                });
            }),
            catchError(e => this.catchError(e))
        );
    }

    @Action(EditLessonSchedule)
    public editLessonsSchedule(ctx: StateContext<ILessonsScheduleStateModel>, { payload }: EditLessonSchedule) {
        const group = ctx.getState()?.group;

        if (!group) {
            return;
        }

        return this.schedule.edit(group.id, payload.dto).pipe(
            tap(() => {
                ctx.dispatch(new LoadLessonsSchedule());
            }),
            catchError(e => this.catchError(e))
        );
    }

    @Action(AddLessonSchedule)
    public addLessonsSchedule(ctx: StateContext<ILessonsScheduleStateModel>, { payload }: AddLessonSchedule) {
        const group = ctx.getState()?.group;

        if (!group) {
            return;
        }

        return this.schedule.add(group.id, payload.dto).pipe(
            tap(() => {
                ctx.dispatch(new LoadLessonsSchedule());
            }),
            catchError(e => this.catchError(e))
        );
    }

    @Action(DeleteLessonSchedule)
    public deleteLessonsSchedule(ctx: StateContext<ILessonsScheduleStateModel>, { payload }: DeleteLessonSchedule) {
        const group = ctx.getState()?.group;

        if (!group) {
            return;
        }

        return this.schedule.delete(group.id, payload.id).pipe(
            tap(() => {
                ctx.dispatch(new LoadLessonsSchedule());
            }),
            catchError(e => this.catchError(e))
        );
    }

    @Action(ChangeScheduleFrom)
    public changeScheduleFrom(ctx: StateContext<ILessonsScheduleStateModel>, { payload }: ChangeScheduleFrom) {
        const group = ctx.getState()?.group;

        if (!group) {
            return;
        }

        return this.schedule.changeFrom(group.id, payload.dto).pipe(
            tap(() => {
                ctx.dispatch(new LoadLessonsSchedule());
            }),
            catchError(e => this.catchError(e))
        );
    }

    private catchError(e: HttpErrorResponse): Observable<never> {
        this.errorHandler.parseError(e);
        return throwError(e);
    }
}
